<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
  page: {
    title: "รับรถเข้าคลัง",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "รับรถเข้าคลัง",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "รับรถเข้าคลัง",
          active: false,
        },
      ],
      loading: false,
      selected: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      optionSup: [],
      rowsRo: [],
      pageOptions: [10, 25, 50, 100],
      isHidden: false,
      branch: "",
      type: "",
      selectMode: "single",
      userBranchId: "",
      filter: {
        userBranchId: "",
        supId: "",
        rcvdDate: "",
        rcvdCode: "",
        status: "",
        shippingNumber: "",
      },
      optionStatus: [
        {
          nameTh: "รับรถเข้าคลังแล้ว",
          status: "APV"
        },
        {
          nameTh: "ยกเลิก",
          status: "CAN"
        },
        {
          nameTh: "รออนุมัติ",
          status: "SUBMIT"
        },
        {
          nameTh: "ร่าง",
          status: "DFT"
        }
      ],
      optionBranch: [],
      userBranch: "",
      urlPrint: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "rcvdCode",
          sortable: true,
          label: "รหัสรับรถ",
        },
        {
          key: "shippingNumber",
          sortable: true,
          label: "เลขที่จัดส่ง",
        },
        {
          key: "rcvdDate",
          sortable: true,
          label: "วันที่รับรถ",
        },
        {
          key: "stockNameTh",
          sortable: true,
          label: "คลัง",
        },
        {
          key: "supplierNameTh",
          sortable: true,
          label: "ผู้ผลิต",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ยอดรวม",
          class: "text-end",
        },
        {
          key: "status",
          sortable: true,
          label: "สถานะ",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "action",
          sortable: false,
          label: appConfig.action,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
    this.getData();
    // this.getDataCreate();
  },
  created() {
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/received-vehicle", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.filter.userBranchId != null
                ? this.filter.userBranchId.branchId
                : this.userBranchId,
            supId: this.filter.supId != null ? this.filter.supId.supId : undefined,
            rcvdDate: this.filter.rcvdDate,
            rcvdCode: this.filter.rcvdCode,
            status: this.filter.status != null ? this.filter.status.status: undefined,
            shippingNumber: this.filter.shippingNumber,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.rows.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    getDataSup: function() {
      this.loading = true;
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId: this.filter.userBranchId != null ? this.filter.userBranchId.branchId : this.userBranchId,
          },
        })
        .then((response) => {
          this.optionSup = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
          this.isHidden = true;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows[0];
      this.alertConfirm(this.selected.roId);
    },
    alertConfirm(roId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการออกใบแจ้งหนี้หรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.confirmSubmitInvoice(roId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    deleteReceived(rcvdId) {
      this.rcvdId = rcvdId;
      this.overlayFlag = true;
      useNetw
        .delete("api/received-vehicle/delete", {
          data: {
            rcvdId: atob(this.rcvdId),
          },
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getData();
          // this.getDataCreate();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleSearch() {
      this.getData();
    },
    // showRo() {
    //   this.getDataRo();
    // },
    alertDelete(rcvdId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบใบรับรถเข้าคลังหรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteReceived(rcvdId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    getReceivedPrint(rcvdId) {
      this.rcvdId = rcvdId;
      this.loading = true;
      useNetw
        .get("api/received-vehicle/print", {
          params: {
            rcvdId: this.rcvdId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          // window.open(this.urlPrint);
          window.location = (this.urlPrint);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ออกใบกำกับภาษี:
                        <multiselect
                          v-model="type"
                          label="receiptName"
                          type="search"
                          :options="receipt"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        สาขา:
                        <multiselect
                          v-model="filter.userBranchId"
                          label="nameTh"
                          type="search"
                          :options="optionBranch"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          @input="getDataSup"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ผู้ผลิต:
                        <multiselect
                          v-model="filter.supId"
                          label="nameTh"
                          type="search"
                          :options="optionSup"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        วันที่รับรถเข้าคลัง:
                        <date-picker
                          v-model="filter.rcvdDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        รหัสรับรถ:
                        <b-form-input
                          v-model="filter.rcvdCode"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        สถานะ:
                        <multiselect
                          v-model="filter.status"
                          label="nameTh"
                          type="search"
                          :options="optionStatus"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        เลขที่จัดส่ง:
                        <b-form-input
                          v-model="filter.shippingNumber"
                          type="search"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn float-end m-1"
                    variant="info"
                    type="submit"
                    @click="handleSearch"
                  >
                    <i class="uil-search-alt"></i>
                    ค้นหา
                  </b-button>
                  <b-button
                    class="btn float-end m-1"
                    variant="primary"
                    :to="{
                      name: 'create-received-vehicle',
                    }"
                  >
                    <i class="uil-plus"></i>
                    สร้าง
                  </b-button>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      แสดงผล&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp;รายการ
                    </label>
                  </div>
                </div>
              </div>
              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
                <div class="table-responsive mb-0">
                  <b-table
                    :items="rows"
                    :fields="fields"
                    :current-page="1"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                  >
                    <template #cell(index)="rows">
                      {{ rows.index + 1 }}
                    </template>
                    <template #cell(status)="rowRo">
                      <span
                        class="badge bg-warning font-size-14"
                        v-if="rowRo.item.status === 'DFT'"
                      >
                        ร่าง
                      </span>
                      <span
                        class="badge bg-info font-size-14"
                        v-if="rowRo.item.status === 'SUBMIT'"
                      >
                        รออนุมัติ
                      </span>
                      <span
                        class="badge bg-success font-size-14"
                        v-if="rowRo.item.status === 'APV'"
                      >
                        รับรถเข้าคลังแล้ว
                      </span>
                      <span
                        class="badge bg-danger font-size-14"
                        v-if="rowRo.item.status === 'CAN'"
                      >
                        ยกเลิก
                      </span>
                    </template>
                    <template v-slot:cell(action)="rowsRo">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <router-link
                            href="javascript:void(0);"
                            class="px-2 text-primary"
                            v-b-tooltip.hover
                            :to="{
                              name: 'detail-received-vehicle',
                              params: { rcvdId: rowsRo.item.rcvdIdBase64 },
                            }"
                            title="View"
                          >
                            <i class="uil uil-pen font-size-18"></i>
                          </router-link>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-danger"
                            v-b-tooltip.hover
                            title="Delete"
                            @click="alertDelete(rowsRo.item.rcvdIdBase64)"
                          >
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-success"
                            v-b-tooltip.hover
                            title="Print"
                            @click="getReceivedPrint(rowsRo.item.rcvdId)"
                          >
                            <i class="uil-print font-size-18"></i>
                          </a>
                        </li>
                      </ul>
                    </template>
                  </b-table>
                </div>
              </b-skeleton-wrapper>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rows.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
